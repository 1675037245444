import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { createStore, applyMiddleware } from "redux"
import { Provider } from "react-redux"
import thunk from "redux-thunk"
import { reducer } from "./store"
//import { ThemeProvider } from 'styled-components';
//import greyTheme from './components/Theme'

const store = createStore(reducer, applyMiddleware(thunk))

ReactDOM.render(
  <Provider store={store}>
    {/* <ThemeProvider theme = {greyTheme}> */}
      <App/>
    {/* </ThemeProvider>  */}
  </Provider>,
  document.getElementById("root")
)

